import React, {createContext, useState} from 'react';

import {IHeaderLink, IValuesMenuLink} from '../utils/types/headerTypes';
import {headersNavigation} from '../utils/constants/headerConstants';


interface Props {
  children?: JSX.Element
}

interface DataContextProps {
  dataContext: IHeaderLink[];
  setData: (data: IValuesMenuLink[]) => void;
}

export const HeaderContext = createContext<DataContextProps | undefined>(undefined);

const initialState: IHeaderLink[] = headersNavigation;

export const HeaderProvider: React.FunctionComponent<Props> = ({children}) => {
  const [dataContext, setDataContext] = useState<IHeaderLink[]>(initialState);

  const setData = (newData: IValuesMenuLink[]) => {
    const newContext = newData.reduce((accumulator, currentValue) =>
      changeNameById(
        currentValue.id,
        currentValue.esname,
        currentValue.name,
        accumulator), [...dataContext]);

    setDataContext(newContext);
  };

  const changeNameById =
    (id: string, esName: string | undefined, newName: string, data: IHeaderLink[]) => {
      const arr = [...data];
      const foundIndex = arr.findIndex((item) => item.id === id);
      if (foundIndex !== -1) {
        arr[foundIndex].name = newName;
        arr[foundIndex].esname = esName;
      } else {
        arr.forEach((item) => {
          if (item.additionalMenu) {
            const additionalMenuIndex =
              item.additionalMenu.findIndex((subItem) => subItem.id === id);
            if (additionalMenuIndex !== -1) {
              item.additionalMenu[additionalMenuIndex].name = newName;
              item.additionalMenu[additionalMenuIndex].esname = esName;
            }
          }
          if (item.additionalFooterMenu) {
            const additionalFooterMenuIndex = item.additionalFooterMenu.findIndex(
              (subItem) => subItem.id === id,
            );
            if (additionalFooterMenuIndex !== -1) {
              item.additionalFooterMenu[additionalFooterMenuIndex].name = newName;
              item.additionalFooterMenu[additionalFooterMenuIndex].esname = esName;
            }
          }
        });
      }

      return arr;
    };

  return (
    <HeaderContext.Provider value={{dataContext, setData}}>
      {children}
    </HeaderContext.Provider>
  );
};
