import React, {useContext, useEffect} from 'react';
import {Routes, Route, useLocation} from 'react-router-dom';

import Layout from '../../layout/Layout';
import Home from '../home/Home';
import Contacts from '../contacts/Contacts';
import ForDevelopers from '../forDevelopers/ForDevelopers';
import PciDssCertificate from '../pciDssCertificate/PciDssCertificate';
import ReliabilitySafety from '../reliabilitySafety/ReliabilitySafety';
import Integrations from '../integrations/Integrations';
import PrivacyPolicy from '../privacyPolicy/PrivacyPolicy';
import Page404 from '../page404/Page404';
import Sitemap from '../sitemap/Sitemap';
import Blog from '../blog/Blog';
import Article from '../article/Article';
import {ReportsBalanceManagement} from '../reportsBalanceManagement/ReportsBalanceManagement';
import PricingOptions from '../pricingOptions/PricingOptions';
import OfferYourMerchants from '../offerYourMerchants/OfferYourMerchants';
import PaymentOrchestrationTools from '../paymentOrchestration/PaymentOrchestrationTools';
import MerchantManagementTools from '../merchantManagementTools/MerchantManagementTools';
import AboutUs from '../aboutUs/AboutUs';
import CaseSingle from '../caseSingle/CaseSingle';
import UseCases from '../useCases/UseCases';
import {PrivacyPolicyProvider} from '../../context/PrivacyPolicyContext';
import {ContactsProvider} from '../../context/ContactsContext';
import {CaseSingleProvider} from '../../context/CaseSingleContext';
import {OfferYourMerchantsProvider} from '../../context/OfferYourMerchantContexts';
import {ForDevelopersProvider} from '../../context/DevelopersContext';
import {AboutProvider} from '../../context/AboutUsContext';
import {PaymentProvider} from '../../context/PaymentContext';
import {ReliabilitySafetyProvider} from '../../context/ReliabilitySafetyContext';
import {PricingOptionsProvider} from '../../context/PricingOptionsContext';
import {ReportAndBalanceProvider} from '../../context/ReportAndBalanceContext';
import {IntegrationsProvider} from '../../context/IntegrationsContext';
import {MerchantManagementProvider} from '../../context/MerchantManagementContext';
import {HomeProvider} from '../../context/HomeContext';
import {FooterProvider} from '../../context/FooterContext';
import {UseCasesProvider} from '../../context/UseCasesContext';
import {BlogProvider} from '../../context/BlogContext';

import {PATH} from '../../utils/constants/routeConstants';
import {HeaderContext} from '../../context/HeaderContext';
import {Preloader} from '../../components/preloader/Preloader';
import useApi from '../../api/useApi';
import {API_URLS} from '../../api/routes';
import {IValuesMenuLink} from '../../utils/types/headerTypes';

type TMenuData = {
  'menu': IValuesMenuLink[];
}

export const App: React.FunctionComponent = () => {
  const {data, isLoading, error} =
    useApi<TMenuData>(API_URLS.MENU, {method: 'GET'});

  const context = useContext(HeaderContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log('dadada', context);
    if (context && data) {
      context.setData(data['menu']);
    }
  }, [data]);

  if (isLoading || error || !data) {
    return <Preloader />;
  }

  return (
    <FooterProvider>
      <Routes>
        <Route
          path={PATH.home}
          element={
            <Layout>
              <HomeProvider>
                <Home />
              </HomeProvider>
            </Layout>
          }
        />
        <Route
          path={PATH.contacts()}
          element={
            <Layout>
              <ContactsProvider>
                <Contacts />
              </ContactsProvider>
            </Layout>
          }
        />
        <Route
          path={PATH.features('integrations')}
          element={
            <Layout>
              <IntegrationsProvider>
                <Integrations />
              </IntegrationsProvider>
            </Layout>
          }
        />
        <Route
          path={PATH.features('paymentOrchestrationTools')}
          element={
            <Layout>
              <PaymentProvider>
                <PaymentOrchestrationTools />
              </PaymentProvider>
            </Layout>
          }
        />
        <Route
          path={PATH.resources('privacyPolicy')}
          element={
            <Layout>
              <PrivacyPolicyProvider>
                <PrivacyPolicy />
              </PrivacyPolicyProvider>
            </Layout>
          }
        />
        <Route
          path={PATH.sitemap}
          element={
            <Layout>
              <Sitemap />
            </Layout>
          }
        />
        <Route
          path={PATH.features('antiFraudTools')}
          element={
            <Layout>
              <ReliabilitySafetyProvider>
                <ReliabilitySafety />
              </ReliabilitySafetyProvider>
            </Layout>
          }
        />
        <Route
          path={PATH.company('PCIDSScertificate')}
          element={
            <Layout>
              <PciDssCertificate />
            </Layout>
          }
        />
        <Route
          path={PATH.resources('blog')}
          element={
            <Layout>
              <BlogProvider>
                <Blog />
              </BlogProvider>
            </Layout>
          }
        />
        <Route
          path={PATH.features('offerYourMerchants')}
          element={
            <Layout>
              <OfferYourMerchantsProvider>
                <OfferYourMerchants />
              </OfferYourMerchantsProvider>
            </Layout>
          }
        />
        <Route
          path={PATH.developers}
          element={
            <Layout>
              <ForDevelopersProvider>
                <ForDevelopers />
              </ForDevelopersProvider>
            </Layout>
          }
        />
        <Route
          path={PATH.features('financialStatementAndBalanceManagement')}
          element={
            <Layout>
              <ReportAndBalanceProvider>
                <ReportsBalanceManagement />
              </ReportAndBalanceProvider>
            </Layout>
          }
        />
        <Route
          path={PATH.company('eComChargeAndTeam')}
          element={
            <Layout>
              <AboutProvider>
                <AboutUs />
              </AboutProvider>
            </Layout>
          }
        />
        <Route
          path={PATH.features('pricingOptions')}
          element={
            <Layout>
              <PricingOptionsProvider>
                <PricingOptions />
              </PricingOptionsProvider>
            </Layout>
          }
        />
        <Route
          path={PATH.features('merchantManagementTools')}
          element={
            <Layout>
              <MerchantManagementProvider>
                <MerchantManagementTools />
              </MerchantManagementProvider>
            </Layout>
          }
        />
        <Route
          path={'/use-cases/:caseName'}
          element={
            <Layout>
              <CaseSingleProvider>
                <CaseSingle />
              </CaseSingleProvider>
            </Layout>
          }
        />
        <Route
          path={PATH.resources('useCases')}
          element={
            <Layout>
              <UseCasesProvider>
                <UseCases />
              </UseCasesProvider>
            </Layout>
          }
        />
        <Route
          path={PATH.blog}
          element={
            <Layout>
              <Blog />
            </Layout>
          }
        />
        <Route
          path={`${PATH.blog}/:articleName`}
          element={
            <Layout>
              <Article />
            </Layout>
          }
        />
        <Route
          path={'*'}
          element={
            <Layout>
              <Page404 />
            </Layout>
          }
        />
      </Routes>
    </FooterProvider>
  );
};

export default App;
